import { Col, Flex, notification, Row } from "antd";

import { FinanceManagerFinancialsChartsCard } from "./finance-manager-financials-charts-card/FinanceManagerFinancialsChartsCard";
import { FinanceManagerFinancialsDatatablesCard } from "./finance-manager-financials-datatables-card/FinanceManagerFinancialsDatatablesCard";
import { useEffect } from "react";
import { useGetFinancialsInfo } from "financials/hooks";
import { NotesList } from "notes/components";
import variables from "../../../../_variables.scss";
import { SalesTargetTimePeriods } from "users/components";

export const FinanceManagerDashboard = () => {
  const { getFinancialsInfo, error, success } = useGetFinancialsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getFinancialsInfo();
  };

  useEffect(() => {
    getFinancialsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Flex vertical gap={variables.largeGap}>
      {notificationContextHolder}
      <Flex>
        <SalesTargetTimePeriods
          name='sales-target-time-periods'
          onChange={onSalesTargetTimePeriodChange}
        />
      </Flex>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 32 }} justify={"space-between"}>
        <Col span={16}>
          <FinanceManagerFinancialsChartsCard loading={!success} />
        </Col>
        <Col span={8}>
          <NotesList title={"My Project Notes"} showCard />
        </Col>
      </Row>
      <FinanceManagerFinancialsDatatablesCard loading={!success} />
    </Flex>
  );
};
