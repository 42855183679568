import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { VerificationTypes } from "auth/constants";
import { useBoundStore } from "store";
import { ISimpleUser } from "users/models";
import { userService } from "users/services";
import { RcFile } from "antd/es/upload";
import { isEmpty, omit } from "lodash";

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<ISimpleUser | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const { setUser } = useBoundStore();
  const currentUser = useBoundStore((state) => state.user);
  const navigate = useNavigate();

  const updateUser = async (
    userToUpdate: ISimpleUser = {},
    id?: string,
    file?: RcFile
  ) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData(undefined);
      let idToUpdate = id;
      let propertiesToOmit = ["roleId", "uid", "profilePicture", "id", "gsi"];

      if (!id && !currentUser?.id) {
        throw new Error("User id is required.");
      }

      if (!id) {
        idToUpdate = currentUser.id;
        userToUpdate = { ...currentUser, ...userToUpdate };
      } else {
        const fullUserToUpdate = await userService.getUser(
          idToUpdate as string
        );
        userToUpdate = { ...fullUserToUpdate, ...userToUpdate };
      }

      if (isEmpty(userToUpdate?.role?.name)) {
        propertiesToOmit.push(...["role"]);
      }

      const user = await userService.updateUser(
        idToUpdate as string,
        omit(userToUpdate, propertiesToOmit),
        file,
        !id
      );

      if (!id) {
        setUser(user);
      }

      if (!id && currentUser?.email !== user?.email) {
        navigate({
          pathname: "/verify",
          search: createSearchParams({
            email: user.email ?? "",
            type: VerificationTypes.EMAIL,
          }).toString(),
        });
      }

      setData(user);
      setSuccess(true);
    } catch (error) {
      setData(undefined);
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUser,
    data,
    loading,
    success,
    error,
  };
};
