import { Image, Card, Flex } from "antd";

import { SalesTargets } from "users/components";
import actualSalesIcon from "sales/assets/images/actual_sales.svg";
import targetSalesIcon from "sales/assets/images/target_sales.svg";
import newSalesIcon from "sales/assets/images/new_sales.svg";
import {
  SalesByUtility,
  SalesLeaderboard,
  TargetVsActualSales,
} from "sales/components";
import { utilityService } from "shared/services";
import { StatisticCard } from "shared/components";
import { useBoundStore } from "store";
import variables from "../../../../../_variables.scss";

export const ProgramManagerDashboardSalesCard = ({
  loading,
}: {
  loading?: boolean;
}) => {
  const { salesCards } = useBoundStore();

  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>Sales</h1>
        <SalesTargets name='sales-targets' />
        <TargetVsActualSales />
        <Flex gap={variables.gap}>
          <StatisticCard
            title='Target Sales'
            value={salesCards?.targetSales ?? 0}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.taupeColor}
            Icon={() => (
              <Image
                src={targetSalesIcon}
                alt='Target Sales Icon'
                preview={false}
                width={35}
              />
            )}
            width={variables.fullWidth}
          />
          <StatisticCard
            title='Actual Sales'
            value={salesCards?.actualSales ?? 0}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.paleGreenColor}
            Icon={() => (
              <Image
                src={actualSalesIcon}
                alt='Actual Sales Icon'
                preview={false}
                width={35}
              />
            )}
            width={variables.fullWidth}
          />
          <StatisticCard
            title='New Sales'
            value={salesCards?.newSales ?? 0}
            precision={0}
            backgroundColor={variables.lightBlueColor}
            Icon={() => (
              <Image
                src={newSalesIcon}
                alt='New Sales Icon'
                preview={false}
                width={35}
              />
            )}
            width={variables.fullWidth}
          />
        </Flex>
        <Flex gap={variables.gap}>
          <SalesLeaderboard dataLoading={loading} />
          <SalesByUtility height={variables.defaultComponentHeight} />
        </Flex>
      </Flex>
    </Card>
  );
};
