import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { RcFile } from "antd/es/upload";

import { ISelectOption } from "shared/models";
import { httpService } from "shared/services";
import { authService } from "auth/services";
import { ISimpleUser, IUserRoleSelectOption } from "../models";
import { isArray, isObject } from "lodash";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/users`;

export const getUser = async (id: string) => {
  const res = await httpService.instance.get<ISimpleUser>(`${apiPath}/${id}`);
  return res?.data;
};

export const getSalesReps = async () => {
  const res = await httpService.instance.get<ISelectOption[]>(
    `${apiPath}/salesreps`
  );
  return res?.data;
};

export const updateUser = async (
  id: string,
  userToUpdate: ISimpleUser,
  file?: RcFile,
  isCurrentUser = true
) => {
  try {
    const userAttributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: userToUpdate?.email?.trim() as string,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: `${userToUpdate?.firstName} ${userToUpdate?.lastName}`.trim(),
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: userToUpdate?.firstName?.trim() as string,
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: userToUpdate?.lastName?.trim() as string,
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: userToUpdate?.phoneNumber?.trim() as string,
      }),
    ];
    const formData = new FormData();

    for (const key in userToUpdate) {
      if (userToUpdate.hasOwnProperty(key)) {
        formData.append(
          key,
          isArray((userToUpdate as any)[key])
            ? (userToUpdate as any)[key].join(",")
            : isObject((userToUpdate as any)[key])
              ? JSON.stringify((userToUpdate as any)[key])
              : (userToUpdate as any)[key]
        );
      }
    }

    if (file) {
      formData.append("profile_picture", file);
    }

    if (isCurrentUser) {
      await authService.updateAttributes(userAttributeList);
    }

    const res = await httpService.instance.put<ISimpleUser>(
      `${apiPath}/${id}`,
      formData
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addUser = async (user: ISimpleUser) => {
  const res = await httpService.instance.post<ISimpleUser>(`${apiPath}`, user);
  return res?.data;
};

export const deleteUser = async (id: string) => {
  try {
    const res = await httpService.instance.delete<string>(`${apiPath}/${id}`);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserSelectOptions = async () => {
  const res = await httpService.instance.get<IUserRoleSelectOption[]>(
    `${apiPath}/select`
  );
  return res?.data;
};

export const getUserMentionOptions = async () => {
  const res = await httpService.instance.get<ISelectOption[]>(
    `${apiPath}/mention`
  );
  return res?.data;
};
