import { Row, Table } from "antd";
import React from "react";

import { useBoundStore } from "store";
import { AuditAppointmentsColumns } from "audit/constants";
import variables from "../../../_variables.scss";

export const AuditAppointments = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = AuditAppointmentsColumns.map((item) => ({
    ...item,
  }));
  const { auditAppointments } = useBoundStore();

  return (
    <Row>
      <Table
        columns={tableColumns}
        dataSource={auditAppointments}
        className='audit-appointments-table'
        ref={tblRef}
        rowKey='id'
        pagination={false}
        scroll={{ y: variables.defaultComponentHeight }}
      />
    </Row>
  );
};
