import { utilityService } from "shared/services";
import { IOpenAccountsReceivableIncentive } from "financials/models";
import { ColumnsType, ROW_INDEX_COLUMN } from "shared/constants";

export const OpenAccountsReceivableIncentiveColumns: ColumnsType<IOpenAccountsReceivableIncentive> =
  [
    ROW_INDEX_COLUMN,
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Incentive Billed Date",
      dataIndex: "incentiveBilledDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Incentive Billed Amount",
      dataIndex: "incentiveBilledAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Accrual / Batch Date",
      dataIndex: "accrualBatchDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Days Aging",
      dataIndex: "daysAging",
    },
    {
      title: "Incentive Received Date",
      dataIndex: "incentiveReceivedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Incentive Received Amount",
      dataIndex: "incentiveReceivedAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
