import { valueType } from "antd/es/statistic/utils";
import dayjs from "dayjs";
import { isEmpty, truncate } from "lodash";

import { DateFormat } from "shared/constants";

export const truncateField = (field: string, length: number = 40) =>
  truncate(field, { length });

export const statisticsFormatter = (value: valueType) =>
  new Intl.NumberFormat("en-US").format(Number(value));

export const moneyFormatter = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
  }).format(Number(value));

export const dateFormatter = (date: Date) =>
  isEmpty(date) ? "N/A" : dayjs(date).format(DateFormat);

export const timeFormatter = (date: Date) =>
  isEmpty(date) ? "N/A" : dayjs(date).format("hh:mm A");

export const phoneNumberFormatter = (phone: string) =>
  phone?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

export const ifEmptyUseNotAvailable = (value: string) =>
  isEmpty(value) || value === "null" ? "N/A" : value;

export const buildQueryParams = (
  params: Record<string, string | number | boolean | undefined>
) => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value) searchParams.append(key, value.toString());
  }

  return searchParams.toString();
};
