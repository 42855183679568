import { Flex, GetProp, TableProps, Typography } from "antd";
import { utilityService } from "shared/services";
import { ISalesLeaderboard } from "sales/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const SalesLeaderboardColumns: ColumnsType<ISalesLeaderboard> = [
  {
    title: "",
    dataIndex: "id",
    width: 50,
    fixed: "left",
    render: (value, record, index) => index + 1,
    onHeaderCell: () => ({
      width: 50,
    }),
  },
  {
    title: "Sales Person",
    dataIndex: "salesPerson",
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Actual Sales",
    dataIndex: "actualSales",
    render: (value, record, index) =>
      `$${utilityService.statisticsFormatter(value)}`,
  },
];
