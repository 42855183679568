import { Card, Flex, notification } from "antd";

import variables from "../../../../../_variables.scss";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";
import { useEffect } from "react";
import {
  DeficientProjects,
  ProjectsByStage,
  ProjectsTimeTrackingSummary,
} from "projects/components";

export const OwnerDashboardProjectCard = () => {
  const { getProjectsInfo, success, error } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Card
        className='owner-dashboard-project-card-container'
        loading={!success}
        bordered={false}
      >
        <Flex vertical gap={variables.gap}>
          <h1>Projects</h1>
          <Flex gap={variables.gap}>
            <ProjectsByStage height={variables.defaultComponentHeight} />
            <ProjectsTimeTrackingSummary />
          </Flex>
          <DeficientProjects />
        </Flex>
      </Card>
    </>
  );
};
