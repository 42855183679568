import { Col, Flex, Row, Typography } from "antd";
import variables from "../../../_variables.scss";
import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import { useBoundStore } from "store";

export const InstallationsRecap = () => {
  const { installationsRecap } = useBoundStore();
  return (
    <Flex vertical gap={variables.gap}>
      <h2>Installations Recap</h2>
      <Row gutter={variables.gap} justify={"space-between"}>
        <Col span={16}>
          <Typography.Text>Total # of Projects</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.totalProjects}
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.lightBlueColor}
          />
        </Col>
      </Row>
      <Row gutter={variables.gap} justify={"space-between"}>
        <Col span={16}>
          <Typography.Text>HVAC Backlog</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.hvacBacklog}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.paleGreenColor}
          />
        </Col>
      </Row>
      <Row gutter={variables.gap} justify={"space-between"}>
        <Col span={16}>
          <Typography.Text>Lighting Backlog</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.lightingBacklog}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.paleGreenColor}
          />
        </Col>
      </Row>
      <Row gutter={variables.gap} justify={"space-between"}>
        <Col span={16}>
          <Typography.Text>Refrigeration Backlog</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.refBacklog}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.paleGreenColor}
          />
        </Col>
      </Row>
      <Row gutter={variables.gap} justify={"space-between"}>
        <Col span={16}>
          <Typography.Text>Total # of Projects in Closeout</Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.totalProjectsInCloseout}
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.lightBlueColor}
          />
        </Col>
      </Row>
      <Row gutter={variables.gap} justify={"space-between"}>
        <Col span={16}>
          <Typography.Text>
            Revenue of Projects in Closeout Status
          </Typography.Text>
        </Col>
        <Col span={8}>
          <StatisticCard
            value={installationsRecap?.totalProjectsInCloseoutRevenue}
            prefix='$'
            formatter={utilityService.statisticsFormatter}
            backgroundColor={variables.paleGreenColor}
          />
        </Col>
      </Row>
    </Flex>
  );
};
