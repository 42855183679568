import { Card, Flex } from "antd";

import { SalesPipelineProjects } from "sales/components";
import { PipelineByProjectsStage } from "sales/components/pipeline-by-projects-stage/PipelineByProjectsStage";
import { PipelineDetails } from "sales/components/pipeline-details/PipelineDetails";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const SalesRepDashboardSalesPipelineCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>My Sales Pipeline</h1>
        <SalesPipelineProjects />
        <PipelineDetails />
        <PipelineByProjectsStage />
      </Flex>
    </Card>
  );
};
