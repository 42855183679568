import { Dropdown, MenuProps, Space, Typography } from "antd";
import "./UserMenu.scss";
import { CaretDownOutlined } from "@ant-design/icons";
import { useBoundStore } from "store";
import { useLogout } from "auth/hooks";
import { Link } from "react-router-dom";

export const UserMenu = () => {
  const { logout } = useLogout();
  const user = useBoundStore((state) => state.user);
  const name =
    `${user?.firstName ?? ""} ${user?.lastName ? `${user?.lastName[0]}.` : ""}`.trim();

  const onLogoutHandler = async () => {
    await logout();
  };

  const items: MenuProps["items"] = [
    {
      label: <Link to='/dashboard/settings'>Settings</Link>,
      key: "0",
    },
    {
      label: (
        <Typography.Link onClick={onLogoutHandler}>Logout</Typography.Link>
      ),
      key: "1",
    },
  ];

  return (
    <Dropdown className='user-menu-container' menu={{ items }}>
      <Space>
        <Typography.Text className='user-menu-title'>{name}</Typography.Text>
        <CaretDownOutlined className='user-menu-icon' />
      </Space>
    </Dropdown>
  );
};
