import { Image, Flex } from "antd";

import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import searchIcon from "projects/assets/images/search.svg";
import prepIcon from "projects/assets/images/prep.svg";
import engineeringIcon from "projects/assets/images/engineering.svg";
import variables from "../../../_variables.scss";

import { useBoundStore } from "store";

export const EngineeringProjects = () => {
  const { engineeringProjects } = useBoundStore();
  return (
    <Flex justify='space-between' gap={variables.gap}>
      <StatisticCard
        width={variables.fullWidth}
        title='Projects in Auditing Stage'
        value={engineeringProjects?.projectsInAuditingStage}
        formatter={utilityService.statisticsFormatter}
        backgroundColor={variables.taupeColor}
        Icon={() => (
          <Image
            src={searchIcon}
            alt='Search Icon'
            preview={false}
            width={35}
          />
        )}
        textAlign='left'
      />
      <StatisticCard
        width={variables.fullWidth}
        title='Projects in Engineering Prep Stage'
        value={engineeringProjects?.projectsInEngineeringPrepStage}
        formatter={utilityService.statisticsFormatter}
        backgroundColor={variables.paleGreenColor}
        Icon={() => (
          <Image src={prepIcon} alt='Prep Icon' preview={false} width={35} />
        )}
        textAlign='left'
      />
      <StatisticCard
        width={variables.fullWidth}
        title='Projects in Engineering Stage'
        value={engineeringProjects?.projectsInEngineeringStage}
        precision={0}
        backgroundColor={variables.lightBlueColor}
        Icon={() => (
          <Image
            src={engineeringIcon}
            alt='Engineering Icon'
            preview={false}
            width={35}
          />
        )}
        textAlign='left'
      />
    </Flex>
  );
};
