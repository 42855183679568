import { Card, Flex } from "antd";

import "./FinanceManagerFinancialsDatatablesCard.scss";

import {
  OpenAccountsReceivableIncentive,
  OpenAccountsReceivableFinanced,
  OpenAccountsReceivableCustomerDirect,
  OpenProjectsDepositsReceived,
} from "financials/components";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const FinanceManagerFinancialsDatatablesCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <OpenAccountsReceivableIncentive />
        <OpenAccountsReceivableFinanced />
        <OpenAccountsReceivableCustomerDirect />
        <OpenProjectsDepositsReceived />
      </Flex>
    </Card>
  );
};
