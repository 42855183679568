import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider, ThemeConfig } from "antd";

import {
  SignUp,
  Login,
  PasswordRecovery,
  Logout,
  PageNotFound,
  VerifyCode,
} from "auth/pages";
import { Dashboard } from "dashboard/pages";
import { ProtectedRoute } from "auth/components";
import { Settings } from "users/pages";
import { Projects } from "projects/pages";
import { Forbidden } from "auth/pages/forbidden/Forbidden";
import { DashboardSelector } from "dashboard/components";
import { UserAdmin } from "admin/pages";

import variables from "./_variables.scss";

// routing configurations
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/forbidden",
    element: <Forbidden />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/recovery/password",
    element: <PasswordRecovery />,
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute ComponentRoute={Dashboard} />,
    children: [
      {
        path: "",
        element: <DashboardSelector />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "/projects",
    element: (
      <ProtectedRoute
        ComponentRoute={Projects}
        permissionsNeededToAccessRoute={["project_get"]}
      />
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute
        ComponentRoute={UserAdmin}
        permissionsNeededToAccessRoute={["user_admin_create"]}
      />
    ),
  },
  {
    path: "/verify",
    element: <VerifyCode />,
  },
]);

const config: ThemeConfig = {
  token: {
    colorPrimary: variables.greenColor,
    colorInfo: variables.greenColor,
    wireframe: false,
    colorLink: variables.blueColor,
    borderRadius: variables.borderRadius,
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
  },
  components: {
    Input: {
      controlHeightLG: 64,
    },
    Button: {
      controlHeightLG: 48,
    },
    Layout: {
      lightSiderBg: variables.lightGreyColor,
    },
    Menu: {
      itemBg: variables.lightGreyColor,
      itemColor: variables.darkGreyColor,
      itemSelectedBg: variables.greenColor,
      itemSelectedColor: variables.whiteColor,
      itemHoverBg: variables.lightGreenColor,
      itemHoverColor: variables.mediumGreenColor,
    },
    Table: {
      headerBg: variables.whiteColor,
      headerColor: "#425166",
      borderColor: "#d8d8d8",
      footerBg: variables.greenColor,
    },
    Modal: {
      titleFontSize: 24,
    },
    Breadcrumb: {
      iconFontSize: 16,
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ConfigProvider theme={config}>
    <RouterProvider router={router} />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
