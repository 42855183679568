import { Card, Flex } from "antd";

import { EngineeringProjects } from "projects/components";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const EngineerDashboardProjectsCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>Projects</h1>
        <EngineeringProjects />
      </Flex>
    </Card>
  );
};
