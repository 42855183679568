import { utilityService } from "shared/services";
import { IOpenAccountsReceivableFinanced } from "financials/models";
import { ColumnsType, ROW_INDEX_COLUMN } from "shared/constants";

export const OpenAccountsReceivableFinancedColumns: ColumnsType<IOpenAccountsReceivableFinanced> =
  [
    ROW_INDEX_COLUMN,
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Billed Finance Amount Date",
      dataIndex: "billedFinancedAmountDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Billed Finance Amount",
      dataIndex: "billedFinancedAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Accrual / Batch Date",
      dataIndex: "accrualBatchDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Days Aging",
      dataIndex: "daysAging",
    },
    {
      title: "Customer Financed Portion Received Date",
      dataIndex: "customerFinancedPortionReceivedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Customer Financed Portion Received Amount",
      dataIndex: "customerFinancedPortionReceivedAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
