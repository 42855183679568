import { Card, Flex } from "antd";

import {
  DepositStatuses,
  OpenAccountsReceivableStatuses,
} from "financials/components";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const FinanceManagerFinancialsChartsCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>Financials</h1>
        <DepositStatuses height={500} />
        <OpenAccountsReceivableStatuses height={500} />
      </Flex>
    </Card>
  );
};
