import { Badge } from "antd";
import { utilityService } from "shared/services";
import { IAuditAppointment } from "audit/models";
import { isEmpty } from "lodash";
import variables from "../../_variables.scss";
import { ColumnsType, ROW_INDEX_COLUMN } from "shared/constants";

export const AuditAppointmentsColumns: ColumnsType<IAuditAppointment> = [
  {
    ...ROW_INDEX_COLUMN,
    render: (value, record, index) => (
      <Badge
        style={
          isEmpty(record?.auditScheduleDate)
            ? {
                backgroundColor: variables.yellowColor,
                color: variables.blackColor,
              }
            : {
                backgroundColor: variables.greenColor,
                color: variables.whiteColor,
              }
        }
        count={index + 1}
      />
    ),
  },
  {
    title: "Project",
    dataIndex: "projectName",
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Address",
    dataIndex: "address",
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Audit Scheduled Date",
    dataIndex: "auditScheduleDate",
    render: (value, record, index) => utilityService.dateFormatter(value),
  },
  {
    title: "Onsite Email",
    dataIndex: "onsiteEmail",
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Onsite Phone",
    dataIndex: "onsitePhone",
    render: (value, record, index) =>
      utilityService.phoneNumberFormatter(value),
  },
];
