import { Empty, Flex } from "antd";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

import { useBoundStore } from "store";

import { utilityService } from "shared/services";
import { ChartColors } from "shared/constants";
import variables from "../../../_variables.scss";
import { useState } from "react";
import { IInstallationBySubcontractor } from "projects/models";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 75) * sin;
  const ex = mx + (cos >= 0 ? 1 : -3) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {utilityService.truncateField(payload.subcontractor, 20)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <foreignObject
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        width='100'
        height='100%'
        style={{
          fontSize: variables.smallFontSize,
          color: variables.darkGreyColor,
        }}
        requiredFeatures='http://www.w3.org/TR/SVG11/feature#Extensibility'
      >
        <p
          style={{
            marginBottom: 0,
          }}
        >
          {payload.subcontractor} ({payload.totalProjects ?? 0}){" "}
          {utilityService.moneyFormatter(payload.amount)}
        </p>
        <p
          style={{
            fontSize: variables.smallFontSize,
            color: variables.lightDarkGreyColor,
            marginTop: 0,
          }}
        >{`(${(percent * 100).toFixed(2)}%)`}</p>
      </foreignObject>
    </g>
  );
};

type Props = {
  width?: number | string;
  height?: number | string;
};

export const HvacInstallationsBySubcontractor = ({
  width = variables.fullWidth,
  height = variables.fullHeight,
}: Props) => {
  const { hvacInstallationsBySubcontractor } = useBoundStore();
  const [activeIndex, setActiveIndex] = useState(0);
  const hasData = hvacInstallationsBySubcontractor?.some(
    (s: IInstallationBySubcontractor) => s.totalProjects > 0
  );

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <Flex
      vertical
      gap={variables.smallGap}
      style={{ width, height, minHeight: variables.defaultComponentHeight }}
    >
      <h2>HVAC Installations By Subcontractor</h2>
      {hasData ? (
        <ResponsiveContainer
          width={variables.fullWidth}
          height={variables.fullHeight}
        >
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              dataKey='totalProjects'
              nameKey='status'
              data={hvacInstallationsBySubcontractor}
              cx='50%'
              cy='45%'
              innerRadius={70}
              outerRadius={100}
              onMouseEnter={onPieEnter}
            >
              {hvacInstallationsBySubcontractor?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={ChartColors[index % ChartColors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Empty />
      )}
    </Flex>
  );
};
