import { Card, Empty, Flex, Progress, Typography } from "antd";

import { useBoundStore } from "store";

import variables from "../../../_variables.scss";

type Props = {
  width?: number | string;
};

export const ProjectsTimeTrackingSummary = ({
  width = variables.fullWidth,
}: Props) => {
  const { projectsTimeTrackingSummary } = useBoundStore();
  const hasData = projectsTimeTrackingSummary?.length > 0;

  return (
    <Flex vertical gap={variables.gap} style={{ width }}>
      <h2>All Projects - Time Tracking Summary</h2>
      {hasData ? (
        <Flex gap={variables.gap} wrap>
          {projectsTimeTrackingSummary?.map((p) => (
            <Card key={p.stage} style={{ width: "45%" }}>
              <Typography.Text>{p.stage}</Typography.Text>
              <Flex gap={variables.smallGap}>
                <Progress percent={p.percentage} showInfo={false} />
                <Typography.Text strong>{p.expectedDays} days </Typography.Text>
              </Flex>
              <Typography.Text>
                <Typography.Text strong>
                  {p.daysLeftFromTarget} days{" "}
                </Typography.Text>
                left from target
              </Typography.Text>
            </Card>
          ))}
        </Flex>
      ) : (
        <Empty />
      )}
    </Flex>
  );
};
