import { Card, Flex } from "antd";

import {
  CloseoutsBySubcontractor,
  InstallationsBySubcontractor,
  InstallationsRecap,
} from "projects/components";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const OperationsManagerDashboardInstallationsCard = ({
  loading,
}: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>Projects</h1>
        <InstallationsRecap />
        <InstallationsBySubcontractor />
        <CloseoutsBySubcontractor />
      </Flex>
    </Card>
  );
};
