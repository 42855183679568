import { Card, Flex } from "antd";

import { AuditAppointments } from "audit/components";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const AuditAppointmentsCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>Audit Appointments</h1>
        <AuditAppointments />
      </Flex>
    </Card>
  );
};
