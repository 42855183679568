import { Card, Flex } from "antd";

import {
  DeficientProjects,
  ProjectsInCloseoutStage,
} from "projects/components";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const OperationsManagerDashboardProjectsCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <ProjectsInCloseoutStage />
        <DeficientProjects />
      </Flex>
    </Card>
  );
};
