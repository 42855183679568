import { Card } from "antd";

import { DeficientProjects } from "projects/components";

type Props = {
  loading?: boolean;
};

export const ContractorDashboardDeficientProjectsCard = ({
  loading,
}: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <DeficientProjects />
    </Card>
  );
};
