import { Col, Flex, notification, Row } from "antd";

import "./OwnerDashboard.scss";
import { OwnerDashboardSalesCard } from "./owner-dashboard-sales-card/OwnerDashboardSalesCard";
import { OwnerDashboardProjectCard } from "./owner-dashboard-project-card/OwnerDashboardProjectCard";
import { SalesTargetTimePeriods } from "users/components";
import { useGetSalesInfo } from "sales/hooks";
import { useEffect } from "react";
import { useBoundStore } from "store";
import variables from "../../../../_variables.scss";
import { NotesList } from "notes/components";

export const OwnerDashboard = () => {
  const { getSalesInfo, success, error } = useGetSalesInfo();
  const [api, notificationContextHolder] = notification.useNotification();
  const { user } = useBoundStore();

  const onSalesTargetTimePeriodChange = () => {
    getSalesInfo();
  };

  useEffect(() => {
    getSalesInfo();
  }, [getSalesInfo, user]);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Flex vertical gap={variables.largeGap}>
      {notificationContextHolder}
      <Flex>
        <SalesTargetTimePeriods
          name='sales-target-time-periods'
          onChange={onSalesTargetTimePeriodChange}
        />
      </Flex>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 32 }} justify={"space-between"}>
        <Col span={17}>
          <OwnerDashboardSalesCard loading={!success} />
        </Col>
        <Col span={7}>
          <NotesList title='My Project Notes' showCard />
        </Col>
      </Row>

      <OwnerDashboardProjectCard />
    </Flex>
  );
};
