import { Flex, Table, Typography } from "antd";
import React from "react";
import { useBoundStore } from "store";
import { OpenAccountsReceivableIncentiveColumns } from "financials/constants";
import { utilityService } from "shared/services";
import variables from "../../../_variables.scss";

export const OpenAccountsReceivableIncentive = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenAccountsReceivableIncentiveColumns?.map((item) => ({
    ...item,
  }));

  const { openAccountsReceivableIncentive } = useBoundStore();

  return (
    <Flex vertical gap={variables.gap}>
      <h2>Open Accounts Receivable - Incentive</h2>
      <Table
        columns={tableColumns}
        dataSource={openAccountsReceivableIncentive}
        ref={tblRef}
        rowKey='id'
        pagination={false}
        summary={(pageData) => {
          let totalIncentiveBilledAmount = 0;
          let totalIncentiveReceivedAmount = 0;

          pageData.forEach(
            ({ incentiveBilledAmount, incentiveReceivedAmount }) => {
              totalIncentiveBilledAmount += Number(incentiveBilledAmount ?? 0);
              totalIncentiveReceivedAmount += Number(
                incentiveReceivedAmount ?? 0
              );
            }
          );

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <Typography.Text strong>TOTALS</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={4}>
                  <Typography.Text strong>
                    $
                    {utilityService.statisticsFormatter(
                      totalIncentiveBilledAmount
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Typography.Text strong>
                    $
                    {utilityService.statisticsFormatter(
                      totalIncentiveReceivedAmount
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        scroll={{ y: variables.defaultComponentHeight }}
      />
    </Flex>
  );
};
