import { Col, Flex, notification, Row } from "antd";

import { useEffect } from "react";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";
import { OperationsManagerDashboardInstallationsCard } from "./operations-manager-dashboard-installations-card/OperationsManagerDashboardInstallationsCard";
import { OperationsManagerDashboardProjectsCard } from "./operations-manager-dashboard-projects-card/OperationsManagerDashboardProjectsCard";
import variables from "../../../../_variables.scss";
import { SalesTargetTimePeriods } from "users/components";
import { NotesList } from "notes/components";

export const OperationsManagerDashboard = () => {
  const { getProjectsInfo, error, success } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getProjectsInfo();
  };

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Flex vertical gap={variables.largeGap}>
      {notificationContextHolder}
      <Flex>
        <SalesTargetTimePeriods
          name='sales-target-time-periods'
          onChange={onSalesTargetTimePeriodChange}
        />
      </Flex>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 32 }} justify={"space-between"}>
        <Col span={16}>
          <OperationsManagerDashboardInstallationsCard loading={!success} />
        </Col>
        <Col span={8}>
          <NotesList title={"My Project Notes"} showCard />
        </Col>
      </Row>
      <OperationsManagerDashboardProjectsCard loading={!success} />
    </Flex>
  );
};
