import { Flex, Card, Statistic } from "antd";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useBoundStore } from "store";

import "./TargetVsActualSales.scss";
import variables from "../../../_variables.scss";
import { utilityService } from "shared/services";

type CustomTooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: string;
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) =>
  active && payload && payload.length ? (
    <Card bordered={false}>
      <Statistic
        title={label}
        value={payload[0].value}
        valueStyle={{ color: variables.yellowColor }}
        formatter={(v, config) =>
          utilityService.moneyFormatter(parseInt(v.toString()))
        }
      />
      <Statistic
        value={payload[1].value}
        valueStyle={{ color: variables.greenColor }}
        formatter={(v, config) =>
          utilityService.moneyFormatter(parseInt(v.toString()))
        }
      />
    </Card>
  ) : null;

export const TargetVsActualSales = () => {
  const { targetVsActualSales } = useBoundStore();

  return (
    <Flex vertical gap={variables.smallGap}>
      <h2>Target vs Actual Sales</h2>
      <ResponsiveContainer height={400}>
        <BarChart data={targetVsActualSales}>
          <XAxis dataKey='month' tickLine={false} axisLine={false} />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={utilityService.moneyFormatter}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey='targetSalesAmount'
            fill={variables.yellowColor}
            radius={[8, 8, 8, 8]}
          />
          <Bar
            dataKey='actualSalesAmount'
            fill={variables.greenColor}
            radius={[8, 8, 8, 8]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
};
