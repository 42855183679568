import { Col, Row, Typography, Card, Skeleton, Flex } from "antd";

import { AuditMap } from "audit/components";
import { APIProvider } from "@vis.gl/react-google-maps";
import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const AuditMapCard = ({ loading }: Props) => {
  return (
    <Card loading={loading} bordered={false}>
      <Flex vertical gap={variables.gap}>
        <h1>Audit Map</h1>
        <APIProvider
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""}
          libraries={["marker"]}
        >
          <AuditMap />
        </APIProvider>
      </Flex>
    </Card>
  );
};
