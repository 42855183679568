import { Col, Flex, notification, Row } from "antd";

import { useEffect } from "react";
import { useGetSalesPipelineInfo } from "sales/hooks";
import { SalesRepDashboardSalesPipelineCard } from "./sales-rep-dashboard-sales-pipeline-card/SalesRepDashboardSalesPipelineCard";
import variables from "../../../../_variables.scss";
import { SalesTargetTimePeriods } from "users/components";
import { NotesList } from "notes/components";

export const SalesRepDashboard = () => {
  const { getSalesPipelineInfo, error, success } = useGetSalesPipelineInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getSalesPipelineInfo();
  };

  useEffect(() => {
    getSalesPipelineInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Flex vertical gap={variables.largeGap}>
      {notificationContextHolder}
      <Flex>
        <SalesTargetTimePeriods
          name='sales-target-time-periods'
          onChange={onSalesTargetTimePeriodChange}
        />
      </Flex>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 32 }} justify='space-between'>
        <Col span={17}>
          <SalesRepDashboardSalesPipelineCard loading={!success} />
        </Col>
        <Col span={7}>
          <NotesList title='My Project Notes' showCard />
        </Col>
      </Row>
    </Flex>
  );
};
