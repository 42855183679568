import { GetProp, TableProps } from "antd";
import { ColumnType } from "antd/es/table";

export type ColumnsType<T extends object> = GetProp<
  TableProps<T | any>,
  "columns"
>;

export const ROW_INDEX_COLUMN: ColumnType = {
  title: "",
  dataIndex: "id",
  width: 50,
  fixed: "left",
  render: (value, record, index) => index + 1,
  onHeaderCell: () => ({
    width: 50,
  }),
};
