import { Flex, Table, Typography } from "antd";
import React from "react";
import { useBoundStore } from "store";
import { OpenAccountsReceivableCustomerDirectColumns } from "financials/constants";
import { utilityService } from "shared/services";
import variables from "../../../_variables.scss";

export const OpenAccountsReceivableCustomerDirect = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenAccountsReceivableCustomerDirectColumns.map(
    (item) => ({ ...item })
  );

  const { openAccountsReceivableCustomerDirect } = useBoundStore();

  return (
    <Flex vertical gap={variables.gap}>
      <h2>Open Accounts Receivable - Customer Direct</h2>
      <Table
        columns={tableColumns}
        dataSource={openAccountsReceivableCustomerDirect}
        ref={tblRef}
        rowKey='id'
        pagination={false}
        summary={(pageData) => {
          let totalCustomerDepositAmountReceived = 0;
          let totalFinalCustomerBilledAmount = 0;
          let totalFinalCustomerDirectAmountReceived = 0;
          let totalCustomerCosts = 0;

          pageData.forEach(
            ({
              customerDepositAmountReceived,
              finalCustomerBilledAmount,
              finalCustomerDirectAmountReceived,
              totalCustomerCost,
            }) => {
              totalCustomerDepositAmountReceived += Number(
                customerDepositAmountReceived
              );
              totalFinalCustomerBilledAmount += Number(
                finalCustomerBilledAmount
              );
              totalFinalCustomerDirectAmountReceived += Number(
                finalCustomerDirectAmountReceived
              );
              totalCustomerCosts += Number(totalCustomerCost);
            }
          );

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Typography.Text strong>TOTALS</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={3}>
                  <Typography.Text strong>
                    $
                    {utilityService.statisticsFormatter(
                      totalCustomerDepositAmountReceived
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Text strong>
                    $
                    {utilityService.statisticsFormatter(
                      totalFinalCustomerBilledAmount
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={2}>
                  <Typography.Text strong>
                    $
                    {utilityService.statisticsFormatter(
                      totalFinalCustomerDirectAmountReceived
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    ${utilityService.statisticsFormatter(totalCustomerCosts)}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        scroll={{ y: variables.defaultComponentHeight }}
      />
    </Flex>
  );
};
