import { Flex, Table, Typography } from "antd";
import React from "react";
import { useBoundStore } from "store";
import { OpenProjectsDepositsReceivedColumns } from "financials/constants";
import { utilityService } from "shared/services";
import variables from "../../../_variables.scss";

export const OpenProjectsDepositsReceived = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OpenProjectsDepositsReceivedColumns.map((item) => ({
    ...item,
  }));
  const { openProjectsDepositsReceived } = useBoundStore();

  return (
    <Flex vertical gap={variables.gap}>
      <h2>Open Projects Deposits Received</h2>
      <Table
        columns={tableColumns}
        dataSource={openProjectsDepositsReceived}
        ref={tblRef}
        rowKey='id'
        pagination={false}
        summary={(pageData) => {
          let totalProjectAmount = 0;
          let totalDepositAmountReceived = 0;

          pageData.forEach(({ projectAmount, depositAmountReceived }) => {
            totalProjectAmount += Number(projectAmount ?? 0);
            totalDepositAmountReceived += Number(depositAmountReceived ?? 0);
          });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Typography.Text strong>TOTALS</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    ${utilityService.statisticsFormatter(totalProjectAmount)}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <Typography.Text strong>
                    $
                    {utilityService.statisticsFormatter(
                      totalDepositAmountReceived
                    )}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        scroll={{ y: variables.defaultComponentHeight }}
      />
    </Flex>
  );
};
