import {
  Avatar,
  Button,
  Card,
  Empty,
  Flex,
  List,
  notification,
  Spin,
  Tag,
} from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import VirtualList, { ListRef } from "rc-virtual-list";

import { useGetNotes } from "notes/hooks";
import "./NotesList.scss";
import React from "react";
import { DateTimeFormat } from "shared/constants";
import { Link } from "react-router-dom";
import { useUpdateUser } from "users/hooks";
import variables from "../../../_variables.scss";
import { utilityService } from "shared/services";

dayjs.extend(utc);
dayjs.extend(relativeTime);

const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
  event.preventDefault();
  event.stopPropagation();
};

type Props = {
  title: string;
  height?: number;
  projectId?: string;
  allowDismiss?: boolean;
  showProjectName?: boolean;
  showCard?: boolean;
};

export const NotesList = ({
  title,
  projectId,
  height = 1050,
  allowDismiss = true,
  showProjectName = true,
  showCard = false,
}: Props) => {
  const { getNotes, error, success, data, hasMore, loading } = useGetNotes();
  const { updateUser, success: updateUserSuccess } = useUpdateUser();
  const [api, notificationContextHolder] = notification.useNotification();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const listRef = React.useRef<ListRef>(null);
  const [hasInitialized, setHasInitialized] = useState(false);

  const Content = () => (
    <Flex vertical gap={variables.gap}>
      {notificationContextHolder}
      <h1>{title}</h1>
      {data?.length > 0 ? (
        success ? (
          <List itemLayout='horizontal'>
            <VirtualList
              ref={listRef}
              data={data}
              className='notes-list-container'
              height={height}
              itemKey='id'
              onScroll={(e: React.UIEvent<HTMLElement, UIEvent>) => {
                if (
                  e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
                    e.currentTarget.clientHeight + 25 &&
                  hasMore &&
                  !loading
                ) {
                  setScrollTop(e.currentTarget.scrollHeight);
                  getNotes(projectId);
                }
              }}
            >
              {(note) => (
                <List.Item className='notes-list-item'>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        shape='circle'
                        size='small'
                        src={note?.createdByProfilePicture}
                        icon={<UserOutlined />}
                      />
                    }
                    title={
                      <Flex justify='space-between'>
                        <p
                          style={{
                            fontSize: 14,
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          {note?.createdByName}
                        </p>
                        {allowDismiss && (
                          <Button
                            type='text'
                            onClick={async () => {
                              if (!note?.id) return;

                              await updateUser({
                                dismissedNotes: [note?.id],
                              });
                            }}
                            size='small'
                          >
                            <CloseOutlined />
                          </Button>
                        )}
                      </Flex>
                    }
                    description={
                      <Flex vertical gap={variables.smallGap}>
                        <span style={{ fontSize: 10 }}>
                          {dayjs(note?.createdDate).format(DateTimeFormat)}
                        </span>
                        <span style={{ wordWrap: "break-word" }}>
                          {utilityService.truncateField(
                            note?.content ?? "",
                            100
                          )}
                        </span>
                        {showProjectName && note?.projectName && (
                          <Tag
                            className='note-tag'
                            color={variables.greenColor}
                            onMouseDown={onPreventMouseDown}
                          >
                            <Link to={`/projects?id=${note?.projectId}`}>
                              {utilityService.truncateField(
                                note?.projectName,
                                25
                              )}
                            </Link>
                          </Tag>
                        )}
                      </Flex>
                    }
                  />
                </List.Item>
              )}
            </VirtualList>
          </List>
        ) : hasInitialized ? (
          <Spin size='large' className='note-spin' />
        ) : (
          <Empty />
        )
      ) : (
        <Empty />
      )}
    </Flex>
  );

  useEffect(() => {
    getNotes(projectId, true);
  }, [updateUserSuccess]);

  useEffect(() => {
    listRef?.current?.scrollTo({
      index: scrollTop,
      align: "top",
      offset: 15,
    });
  }, [data]);

  useEffect(() => {
    if (success === true) {
      setHasInitialized(true);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {showCard ? (
        <Card className='notes-card' loading={!success} bordered={false}>
          <Content />
        </Card>
      ) : (
        <Content />
      )}
    </>
  );
};
