import { Card, Flex } from "antd";

import {
  HvacInstallationsBySubcontractor,
  OutstandingContractorQuotes,
} from "projects/components";
import { LightingInstallationsBySubcontractor } from "projects/components/lighting-installations-by-subcontractor/LightingInstallationsBySubcontractor";

import variables from "../../../../../_variables.scss";

type Props = {
  loading?: boolean;
};

export const ContractorDashboardProjectsCard = ({ loading }: Props) => {
  return (
    <Card
      className='contractor-dashboard-projects-card-container'
      loading={loading}
      bordered={false}
    >
      <Flex vertical gap={variables.gap}>
        <h1>Projects</h1>
        <HvacInstallationsBySubcontractor
          height={variables.defaultComponentHeight}
        />
        <LightingInstallationsBySubcontractor
          height={variables.defaultComponentHeight}
        />
        <OutstandingContractorQuotes />
      </Flex>
    </Card>
  );
};
