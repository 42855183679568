import { Flex, Form, InputNumber, notification } from "antd";
import { useEffect } from "react";

import "./SalesTargets.scss";
import { useBoundStore } from "store";
import { CaretDownFilled } from "@ant-design/icons";
import { useUpdateUser } from "users/hooks";
import variables from "../../../_variables.scss";

type Props = {
  name: string;
};

export const SalesTargets = ({ name }: Props) => {
  const [form] = Form.useForm();
  const { updateUser, error } = useUpdateUser();
  const [api, notificationContextHolder] = notification.useNotification();
  const { user } = useBoundStore();

  const onBlurHandler = () => {
    const salesTargetAmount = form.getFieldValue("salesTargetAmount");
    const billedProjectsTargetAmount = form.getFieldValue(
      "billedProjectsTargetAmount"
    );
    const completedProjectsTargetAmount = form.getFieldValue(
      "completedProjectsTargetAmount"
    );

    updateUser({
      configuration: {
        salesTargetAmount,
        billedProjectsTargetAmount,
        completedProjectsTargetAmount,
      },
    });
  };

  useEffect(() => {
    if (error) {
      api.error({
        message: "Update user sales target error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <div className='sales-targets-container'>
      {notificationContextHolder}
      <Form
        form={form}
        name={`${name}_form`}
        data-testid={`${name}_testid`}
        requiredMark={false}
        layout='vertical'
        initialValues={{
          salesTargetAmount: user?.configuration?.salesTargetAmount ?? 0,
          billedProjectsTargetAmount:
            user?.configuration?.billedProjectsTargetAmount ?? 0,
          completedProjectsTargetAmount:
            user?.configuration?.completedProjectsTargetAmount ?? 0,
        }}
        onBlur={onBlurHandler}
      >
        <Flex vertical gap={variables.smallGap}>
          <Flex gap={variables.gap} align='center'>
            <h2>Enter Targets</h2>
            <CaretDownFilled className='sales-targets-icon' />
          </Flex>
          <Flex gap={variables.gap}>
            <Form.Item
              className='form-item-borderless'
              name='salesTargetAmount'
              label='Sales'
              data-testid={`${name}_salesTargetAmount_testid`}
            >
              <InputNumber size='small' variant='borderless' />
            </Form.Item>
            <Form.Item
              className='form-item-borderless'
              name='billedProjectsTargetAmount'
              label='Billed Projects'
              data-testid={`${name}_billedProjectsTargetAmount_testid`}
            >
              <InputNumber size='small' variant='borderless' />
            </Form.Item>
            <Form.Item
              className='form-item-borderless'
              name='completedProjectsTargetAmount'
              label='Completed Projects'
              data-testid={`${name}_completedProjectsTargetAmount_testid`}
            >
              <InputNumber size='small' variant='borderless' />
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
    </div>
  );
};
