import { IOpenProjectsDepositsReceived } from "financials/models";
import { ColumnsType, ROW_INDEX_COLUMN } from "shared/constants";
import { utilityService } from "shared/services";

export const OpenProjectsDepositsReceivedColumns: ColumnsType<IOpenProjectsDepositsReceived> =
  [
    ROW_INDEX_COLUMN,
    {
      title: "Project",
      dataIndex: "projectName",
      render: (value, record, index) => utilityService.truncateField(value),
    },
    {
      title: "Deposit Invoiced Date",
      dataIndex: "depositInvoicedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "ReceivedDate",
      dataIndex: "receivedDate",
      render: (value, record, index) => utilityService.dateFormatter(value),
    },
    {
      title: "Deposit Amount Received",
      dataIndex: "depositAmountReceived",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
    {
      title: "Project Amount",
      dataIndex: "projectAmount",
      render: (value, record, index) =>
        `$${utilityService.statisticsFormatter(value)}`,
    },
  ];
