import { notification, Card, Flex } from "antd";
import { useEffect } from "react";

import {
  DeficientProjects,
  ProjectsByStage,
  ProjectsTimeTrackingSummary,
} from "projects/components";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";
import variables from "../../../../../_variables.scss";

export const ProgramManagerDashboardProjectCard = () => {
  const { getProjectsInfo, success, error } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {notificationContextHolder}
      <Card loading={!success} bordered={false}>
        <Flex vertical gap={variables.gap}>
          <h1>Projects</h1>
          <Flex gap={variables.gap}>
            <ProjectsByStage height={variables.defaultComponentHeight} />
            <ProjectsTimeTrackingSummary />
          </Flex>
          <DeficientProjects />
        </Flex>
      </Card>
    </>
  );
};
