import { Flex, Table, TableProps } from "antd";
import "./SalesLeaderboard.scss";
import { SalesLeaderboardColumns } from "sales/constants";
import { ISalesLeaderboard } from "sales/models";
import { useBoundStore } from "store";
import variables from "../../../_variables.scss";

type Props = {
  dataLoading?: boolean;
  width?: number | string;
};

export const SalesLeaderboard = ({
  dataLoading,
  width = variables.fullWidth,
}: Props) => {
  const tableColumns = SalesLeaderboardColumns.map((item) => ({ ...item }));
  const tableProps: TableProps<ISalesLeaderboard> = {
    loading: dataLoading,
  };
  const { salesLeaderboard } = useBoundStore();

  return (
    <Flex vertical gap={variables.smallGap} style={{ width }}>
      <h2>Sales Leaderboard</h2>
      <Table
        {...tableProps}
        columns={tableColumns}
        dataSource={salesLeaderboard}
        className='sales-leaderboard-table'
        rowKey='id'
        pagination={false}
        size='small'
        bordered={false}
        scroll={{ y: variables.defaultComponentHeight }}
      />
    </Flex>
  );
};
