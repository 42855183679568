import { Col, Flex, notification, Row } from "antd";

import { useEffect } from "react";
import { useGetAuditInfo } from "audit/hooks";
import { FieldAuditorDashboardAuditAppointmentsCard } from "./field-auditor-dashboard-audit-appointments-card/FieldAuditorDashboardAuditAppointmentsCard";
import { FieldAuditorMapCard } from "./field-auditor-map-card/FieldAuditorMapCard";
import variables from "../../../../_variables.scss";
import { NotesList } from "notes/components";

export const FieldAuditorDashboard = () => {
  const { getAuditInfo, error, success } = useGetAuditInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getAuditInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Flex vertical gap={variables.largeGap}>
      {notificationContextHolder}
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 32 }} justify={"space-between"}>
        <Col span={16}>
          <FieldAuditorMapCard loading={!success} />
        </Col>
        <Col span={8}>
          <NotesList title={"My Project Notes"} showCard />
        </Col>
      </Row>
      <FieldAuditorDashboardAuditAppointmentsCard loading={!success} />
    </Flex>
  );
};
