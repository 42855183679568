import { Card, Empty, Flex, Progress, Typography } from "antd";

import { useBoundStore } from "store";

import "./DeficientProjects.scss";
import { MessageFilled } from "@ant-design/icons";
import variables from "../../../_variables.scss";
import { utilityService } from "shared/services";

export const DeficientProjects = () => {
  const { deficientProjects } = useBoundStore();
  const hasData = deficientProjects?.length > 0;

  return (
    <Flex vertical gap={variables.gap}>
      <h2>Deficient Projects</h2>
      {hasData ? (
        <Flex gap={variables.gap} wrap>
          {deficientProjects?.map((p) => (
            <Card
              className='deficient-projects-card'
              key={p.name}
              style={{ width: "32.5%" }}
            >
              <Flex vertical gap={variables.smallGap}>
                <Typography.Text className='deficient-projects-card-title'>
                  {p.name}
                </Typography.Text>
                <Typography.Text className='deficient-projects-card-subtitle'>
                  {p.mmProjectId?.toUpperCase()}
                </Typography.Text>
                <Typography.Text className='deficient-projects-card-note'>
                  <MessageFilled />{" "}
                  {utilityService.truncateField(
                    p.latestProjectNote?.content ?? "",
                    100
                  )}
                </Typography.Text>
                <Progress
                  percent={p.percent}
                  showInfo={false}
                  status='exception'
                />
                <Typography.Text>
                  <Typography.Text strong>{p.daysOverdue}</Typography.Text> Days
                  Overdue
                </Typography.Text>
                <Typography.Text>
                  <Typography.Text strong>Stage:</Typography.Text> {p.stage}
                </Typography.Text>
              </Flex>
            </Card>
          ))}
        </Flex>
      ) : (
        <Empty />
      )}
    </Flex>
  );
};
