import { Col, Flex, notification, Row } from "antd";

import { useEffect } from "react";
import { useGetProjectsInfo } from "projects/hooks/useGetProjectsInfo";
import { ContractorDashboardProjectsCard } from "./contractor-dashboard-projects-card/ContractorDashboardProjectsCard";
import { ContractorDashboardDeficientProjectsCard } from "./contractor-dashboard-deficient-projects-card/ContractorDashboardDeficientProjectsCard";
import { SalesTargetTimePeriods } from "users/components";
import variables from "../../../../_variables.scss";
import { NotesList } from "notes/components";

export const ContractorDashboard = () => {
  const { getProjectsInfo, error, success } = useGetProjectsInfo();
  const [api, notificationContextHolder] = notification.useNotification();

  const onSalesTargetTimePeriodChange = () => {
    getProjectsInfo();
  };

  useEffect(() => {
    getProjectsInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Flex vertical gap={variables.largeGap}>
      {notificationContextHolder}
      <Flex>
        <SalesTargetTimePeriods
          name='sales-target-time-periods'
          onChange={onSalesTargetTimePeriodChange}
        />
      </Flex>
      <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 32 }} justify={"space-between"}>
        <Col span={17}>
          <ContractorDashboardProjectsCard loading={!success} />
        </Col>
        <Col span={7}>
          <NotesList title='My Project Notes' showCard />
        </Col>
      </Row>
      <ContractorDashboardDeficientProjectsCard loading={!success} />
    </Flex>
  );
};
