import { Image, Flex } from "antd";

import { StatisticCard } from "shared/components";
import { utilityService } from "shared/services";
import projectsIcon from "projects/assets/images/projects.svg";
import actualSalesIcon from "sales/assets/images/actual_sales.svg";
import newSalesIcon from "sales/assets/images/new_sales.svg";
import variables from "../../../_variables.scss";

import { useBoundStore } from "store";

export const SalesPipelineProjects = () => {
  const { salesPipelineProjects } = useBoundStore();
  return (
    <Flex gap={variables.gap}>
      <StatisticCard
        title='Number of Projects'
        value={salesPipelineProjects?.totalProjects ?? 0}
        formatter={utilityService.statisticsFormatter}
        backgroundColor={variables.taupeColor}
        Icon={() => (
          <Image
            src={projectsIcon}
            alt='Projects Icon'
            preview={false}
            width={35}
          />
        )}
        width={variables.fullWidth}
      />
      <StatisticCard
        title='Projects Value'
        value={salesPipelineProjects?.totalProjectsValue ?? 0}
        prefix='$'
        formatter={utilityService.statisticsFormatter}
        backgroundColor={variables.paleGreenColor}
        Icon={() => (
          <Image
            src={actualSalesIcon}
            alt='Actual Sales Icon'
            preview={false}
            width={35}
          />
        )}
        width={variables.fullWidth}
      />
      <StatisticCard
        title='Projects Closing < 30 Days'
        value={salesPipelineProjects?.totalProjectsClosingInThirtyDays ?? 0}
        precision={0}
        backgroundColor={variables.lightBlueColor}
        Icon={() => (
          <Image
            src={newSalesIcon}
            alt='New Sales Icon'
            preview={false}
            width={35}
          />
        )}
        width={variables.fullWidth}
      />
    </Flex>
  );
};
