import { Flex, Table } from "antd";
import React from "react";
import { useBoundStore } from "store";
import { OutstandingContractorQuotesColumns } from "projects/constants";
import variables from "../../../_variables.scss";

export const OutstandingContractorQuotes = () => {
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const tableColumns = OutstandingContractorQuotesColumns.map((item) => ({
    ...item,
  }));
  const { outstandingContractorQuotes } = useBoundStore();

  return (
    <Flex vertical gap={variables.gap}>
      <h2>Outstanding Contractor Quotes ({">"} 2 weeks)</h2>
      <Table
        columns={tableColumns}
        dataSource={outstandingContractorQuotes}
        ref={tblRef}
        rowKey='id'
        pagination={false}
        scroll={{ y: variables.defaultComponentHeight }}
      />
    </Flex>
  );
};
